































































































































































import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import { MyPageViewModel } from '../viewmodels/mypage-viemodel'

@Observer
@Component
export default class MyPageForm extends Vue {
  @Inject() vm!: MyPageViewModel
  wallet = walletStore

  @Watch('wallet.jwt', { immediate: true }) async onAccountChange(val: string) {
    if (!val) {
      this.$router.push('/vault')
    }
  }

  beforeDestroy() {
    this.vm.destroy()
  }
}
