var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header-hero audiowide-font mb-7 mb-md-10 primary--text"},[_vm._v("My page")]),_c('div',[_c('div',{staticClass:"mb-2"},[_vm._m(0),_c('div',{class:{ 'border-error': _vm.vm.walletAddressError, 'border-trans': !_vm.vm.walletAddressError }},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input text-custom--white",attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","color":"","value":_vm._f("shortText")(_vm.wallet.account,_vm.$vuetify.breakpoint.name),"disabled":""}})],1),_c('v-sheet',{staticClass:"transparent mt-2",attrs:{"height":"24"}})],1),_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"label font-weight-bold mb-2"},[_vm._v("KYC approval")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input",class:_vm.wallet.isKycVerified ? 'text-verify' : 'text-error',attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","value":_vm.wallet.kycStatusText.text,"disabled":""}}),_c('v-btn',{staticClass:"ml-2 text-none black--text font-weight-bold letter-spacing-inherit btn-confirm",class:_vm.wallet.isKycVerified ? 'btnA--disabled' : 'error',attrs:{"width":187,"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"loading":_vm.wallet.loadingKycState,"text":"","tile":""},on:{"click":_vm.vm.submitKyc}},[_vm._v(" Proceed with KYC ")])],1),_c('v-sheet',{staticClass:"transparent mt-2",attrs:{"height":"24"}})],1),_c('div',{staticClass:"mb-2"},[_vm._m(1),_c('div',{staticClass:"d-flex"},[_c('v-spacer',{class:{ 'border-error': _vm.vm.emailAddressError, 'border-trans': !_vm.vm.emailAddressError }},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input text-custom--white",class:{
              'text-error': _vm.vm.emailAddressError,
            },attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","value":_vm.vm.emailAddress,"disabled":!_vm.vm.acceptChangeEmail},on:{"input":_vm.vm.onEmailAddressChange}})],1),_c('v-btn',{staticClass:"ml-2 text-none black--text font-weight-bold letter-spacing-inherit btn-confirm primary",attrs:{"width":187,"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"loading":_vm.wallet.loadingKycState,"text":"","tile":""},on:{"click":_vm.vm.changeEmailClick}},[_vm._v(" Change Email ")])],1),_c('v-sheet',{staticClass:"transparent text-end mt-2 error--text",attrs:{"height":"24"}},[_vm._v(_vm._s(_vm.vm.emailAddressError ? 'Wrong address' : ''))])],1),_c('div',{staticClass:"mb-8"},[_vm._m(2),_c('div',{class:{ 'border-error': _vm.vm.usernameError, 'border-trans': !_vm.vm.usernameError }},[_c('v-text-field',{staticClass:"rounded-0 algo-grey darken-1 text-input text-custom--white",class:{
            'text-error': _vm.vm.usernameError,
          },attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"solo":"","dense":"","flat":"","hide-details":"","value":_vm.vm.username,"disabled":""},on:{"input":_vm.vm.onUsernameChange}})],1),_c('v-sheet',{staticClass:"transparent mt-2",attrs:{"height":"24"}})],1)]),_c('div',{staticClass:"mb-10",class:{
      'border-active': _vm.vm.agreePolicy && !_vm.vm.agreePolicyError,
      'border-trans': !_vm.vm.agreePolicy && !_vm.vm.agreePolicyError,
      'border-error': _vm.vm.agreePolicyError,
    }},[_c('v-sheet',{staticStyle:{"cursor":"pointer"},attrs:{"height":_vm.$vuetify.breakpoint.lgAndUp ? '60' : '50',"color":"d-flex align-center pl-5 algo-grey darken-1"},on:{"click":_vm.vm.onAcceptTermChange}},[_c('div',{staticStyle:{"max-width":"20px"}},[(_vm.vm.agreePolicyError)?_c('v-img',{attrs:{"src":require('@/assets/icons/checkbox-error.svg')}}):(_vm.vm.agreePolicy)?_c('v-img',{attrs:{"src":require('@/assets/icons/checkbox-active.svg')}}):_c('v-img',{attrs:{"src":require('@/assets/icons/checkbox-none.svg')}})],1),_c('div',{staticClass:"ml-3 agree-term",class:{ 'primary--text': _vm.vm.agreePolicy && !_vm.vm.agreePolicyError, 'error--text': _vm.vm.agreePolicyError }},[_vm._v(" I agree to the "),_c('router-link',{staticStyle:{"color":"inherit"},attrs:{"to":{ path: '/home' }}},[_vm._v("terms and condition")])],1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-md-row justify-md-space-between"},[_c('div',{staticClass:"label font-weight-bold mb-md-2"},[_vm._v("Wallet address")]),_c('div',{staticClass:"sub-label algo-grey--text text--lighten-1 mb-2 mb-md-0"},[_vm._v(" We will use this wallet for login purpose ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"label font-weight-bold mb-md-2"},[_vm._v("Email address")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column flex-md-row justify-md-space-between"},[_c('div',{staticClass:"label font-weight-bold mb-md-2"},[_vm._v("User name")]),_c('div',{staticClass:"sub-label algo-grey--text text--lighten-1 mb-2 mb-md-0"},[_vm._v("This could be presented publicly")])])}]

export { render, staticRenderFns }